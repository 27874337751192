/* eslint-disable object-shorthand */

export const PACKAGE_BUILD_DATE = '2025-01-23T17:56:10.099Z';
export const PACKAGE_NAME = 'invision-customercare';
export const PACKAGE_VERSION = '25.2.10000-1696757';

export default {
    PACKAGE_BUILD_DATE: PACKAGE_BUILD_DATE,
    PACKAGE_NAME: PACKAGE_NAME,
    PACKAGE_VERSION: PACKAGE_VERSION
};
